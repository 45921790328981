<template>
<Loading v-show="show" />
  <div class="breadcrumb-banner-area">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="breadcrumb-text">
            <h1 class="text-left">{{college}}</h1>
            <div class="breadcrumb-bar">
              <ul class="breadcrumb text-left">
                <li><router-link to="/">{{home}}</router-link></li>
                <li>{{college}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--KnowUniv Details Area Start-->
  <div class="news-details-area section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-12 col-12">
          <div class="news-details-content">
            <div class="single-latest-item">
              <img v-bind:src="imgUniv" />
              <div class="single-latest-text">
                <h3 id="TitleUniv"></h3>             
                <p id="DetailsUniv"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 col-12">
          <div class="sidebar-widget">
            <div class="single-sidebar-widget">
              <h4 class="title"> {{Unvicollege}}</h4>
              <div class="recent-content">
                <div
                  v-for="KnowUniv in KnowUniv"
                  :key="KnowUniv.postId"
                  class="recent-content-item"
                >
                 <router-link  
                        :to="{
                          name: 'Univ-with-id-And-Type',
                          params: {
                            type: $route.params.type,
                            id: KnowUniv.postID,
                          },
                        }"
                      ><img  @click="getDataKnowUniv(KnowUniv.postID,$route.params.type)"
                      class="img_left"
                      v-bind:src="
                        'https://api2.yg-u.net/images/post/' + KnowUniv.postImage
                      "
                  /> </router-link>
                  <div class="recent-text">
                    <p @click="getDataKnowUniv(KnowUniv.postID,$route.params.type)">
                      <router-link  
                        :to="{
                          name: 'Univ-with-id-And-Type',
                          params: {
                            type: $route.params.type,
                            id: KnowUniv.postID,
                          },
                        }"
                      >
                        {{ KnowUniv.postTitle }}
               
                      </router-link>
                    </p>
                    <!-- <p>   {{ KnowUniv.postSubTitle }}</p> -->
                  </div>
                </div>
              </div>
            </div>
             <div class="single-sidebar-widget">
              <ul class="tags">
                <li><router-link  :to="{
            name: 'Univ-with-Type',
            params: {
              type: 'Univ.Home.Sec.KnowUniv',
            },
          }">{{BtnMore}} </router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End of KnowUniv Details Area-->
</template>
<script>
import Loading from './Loading'
import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
    components:{
Loading
  },
  data() {
    return {
      show: true,
      KnowUniv: [],
      Title:'',
      Details:'',
      imgUniv:'',

       //translate
      ArTranslat: ArJson,
      EnTranslat: EnJson,
       translate: "",
       home:"",
       college:"",
      BtnMore:'',
      Detials:'',
      Unvicollege:''
    };
  },
  methods: {
    getDataKnowUniv(PostID, Type) {
      
      var self = this;

        self.show = true;

      window.scroll(0, 0);
      var bodyFormData = new FormData();
       //translate
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
      }
       self.home = self.translate[0]["HeaderAndFooter"]["Home"];
      self.college = self.translate[0]["HeaderAndFooter"]["college"];
      self.Unvicollege = self.translate[0]["Home"]["Unvicollege"];
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
      self.Detials = self.translate[0]["Home"]["Detials"];

       bodyFormData.append("check", "getPostById");
      bodyFormData.append("PostId",PostID)
      bodyFormData.append("Lang", localStorage.getItem('lang'));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", Type);
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yg-u.net/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
          var arr = response.data
          if(arr.length !=0){
          self.imgUniv =  "https://api2.yg-u.net/images/post/" + response.data[0]['postImage'];
          document.getElementById("TitleUniv").innerText = response.data[0]['postTitle']
          document.getElementById("DetailsUniv").innerHTML = response.data[0]['postDetails']
        }

            self.show = false;
        })
        .catch(function (response) {
          console.log("error", response);
            self.show = false;
        });
    
    },
    getApis(){
          
    window.scroll(0, 0);
    var self = this;
   self.getDataKnowUniv(this.$route.params.id,this.$route.params.type)
    var bodyFormData = new FormData();
bodyFormData.append("check", "getPost");
    bodyFormData.append("Lang", localStorage.getItem('lang'));
     bodyFormData.append("PostId", "");
    bodyFormData.append("FbrnId", "");
    bodyFormData.append("Type", this.$route.params.type);
    bodyFormData.append("ParentId", "NTROOT0");
    bodyFormData.append("PostName", "");
    bodyFormData.append("Pno", "-1");
    axios({
      method: "post",
      url: "https://api2.yg-u.net/our_team/getPost",
      data: bodyFormData,
    })
      .then(function (response) {
        self.KnowUniv = response.data;
        document.getElementById("VueMainJsNewTouch")?.remove();
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute("src", "/js/main.js");
        recaptchaScript.id = "VueMainJsNewTouch";
        document.head.appendChild(recaptchaScript);

        setTimeout(() => {
                                      self.show = false;
                                    }, 100);

      })
      .catch(function (response) {
        console.log("error", response);
      });
    }
  },
  mounted() {
        var self = this;

 if (localStorage.getItem('lang') == null){
    axios({
        method: "get",
        url: "https://api2.yg-u.net/languages/getLangIsMain",
      })
        .then(function (response) {
          self.lang = response.data[0]["LangId"];
          localStorage.setItem("lang", response.data[0]["LangId"]);
          if (response.data[0]["LangId"] == "Ar") {
            document.getElementById("langfilewebsites")?.remove();
            self.getApis();
          } else {
            self.translate = self.EnTranslat;
            let link1 = document.createElement("link");
            link1.setAttribute("rel", "stylesheet");
            link1.id = "langfilewebsites";
            link1.setAttribute("href", "../../style-ltr.css");
            document.head.appendChild(link1);
            self.getApis();
          }
        })
        .catch(function (response) {
          console.log("error", response);
        });
    
  }
  else {
      if (localStorage.getItem("lang") == "Ar") {
        document.getElementById("langfilewebsites")?.remove();
        self.getApis();
      } else {
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../style-ltr.css");
        document.head.appendChild(link1);
        self.getApis();
      }
    }
  },
};
</script>

<style scoped>
.title {
  width: 100% !important;
  text-align: right !important ;
  background: #fff !important ;
  position: relative !important ;
}
.single-sidebar-widget .tags li a {
  background: #3498db none repeat scroll 100% 0;
  border: 1px solid #3498db;
  color: #ffffff;
  float: right;
  margin-bottom: 13px;
  margin-left: 6px;
  padding: 11px 35px;
  position: absolute;
}
.img_left {
  height: 70px !important;
  widows: 70px im !important;
}
.recent-text p {
  font-size: 15px;
  line-height: 23px;
}
.single-latest-item img{
  width: 870px;
  height: 435px;
}
.news-details-content .single-latest-text {
    padding: 28px 20px 0px;
    text-align: justify;
}
</style>
